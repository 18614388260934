// @flow

import * as React from "react";
import Results from "../pages/results";
import Home from "../pages/index";

type Step = "Home" | "Results";

type RoutePath = "/" | "/results";

type Route = {
  name: Step,
  to: RoutePath,
  component: React.Node,
};

export const appRoutes: Array<Route> = [
  {
    name: "Results",
    to: "/results",
    component: <Results path="/results" key="results" />,
  },
];

export const routes: Array<Route> = [
  {
    name: "Home",
    to: "/",
    component: <Home key="home" />,
  },
  ...appRoutes,
];
