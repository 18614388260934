// @flow

import * as React from "react";
import { useContext, useState } from "react";
import { Router } from "@reach/router";
import { CMContext } from "../../provider";
import CMStepper from "../components/stepper";
import CMHeader from "../components/header";
import CMImage from "../components/image";
import { appRoutes } from "../utils/routingConfig";
import {
  Box,
  Container,
  Grid,
  Typography
} from "@material-ui/core";
import LocalStorage from "../LocalStorage";
import theme from '../theme';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  CMApiCallJobData,
  useColorMotiveAPI,
  getCMApiCallJobData,
} from "../utils/colormotive_api_calls";
import { useSiteAnalysisStep } from "../utils/customHooks";
import Head from '../components/head';

const App = (): React.Node => {
  const { state } = useContext(CMContext);
  const { SiteAnalysisSteps, setSiteAnalysisStep } = useSiteAnalysisStep();
  const [screenshotSrc, setScreenshotSrc] = useState(null);
  const [websiteURL, setWebsiteURL] = useState(null);
  const jobID = state.cm_api_info.job_id ?? LocalStorage.get();

  const { isLoading } = useColorMotiveAPI(
    () => getCMApiCallJobData(jobID, CMApiCallJobData.SCREENSHOT),
    false,
    true,
    // update the site analysis progress on complete
    (response) => {
      setSiteAnalysisStep("comparing");
      setScreenshotSrc(response?.data?.screenshot ?? "");
    },
  );

  const { isURLLoading } = useColorMotiveAPI(
    () =>
      getCMApiCallJobData(jobID, CMApiCallJobData.URL),
      false,
      true,
      (response) => setWebsiteURL(response?.data?.url ?? ""),
  );

  return (
    <ThemeProvider theme={theme}>
      <Head />
      <CMHeader />
      <Container>
        <Box maxWidth={600} mx="auto" p={5}>
          <CMStepper />
        </Box>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <Box p={3}>
              <Typography component="h1" variant="h5">
                {websiteURL}
              </Typography>
            </Box>
            <Box p={3} minHeight={260.58}>
              <CMImage alt="Website snapshot" isLoading={isLoading} src={screenshotSrc} />
            </Box>
            <SiteAnalysisSteps />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Router basepath="/app">
              {appRoutes.map((route) => route.component)}
            </Router>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default App;
