// @flow

import * as React from "react";
import { useState, useEffect } from "react";
import { routes } from "../utils/routingConfig";
import {
  Link,
  Step,
  StepLabel,
  Stepper,
  Typography
 } from "@material-ui/core";
import { Link as RouterLink } from "gatsby";
import { useLocation } from "@reach/router";

const CMStepper = (): React.Node => {
  const { pathname } = useLocation();
  const currentStep = routes.findIndex((route) => route.to === pathname);
  const [activeStep, setActiveStep] = useState(currentStep);

  // The useEffect will track any changes from pathname, which is a hook
  // that tracks the current path. This allows the stepper to update when
  // the path changes.
  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep, setActiveStep]);

  return (
    <Stepper activeStep={activeStep}>
      {routes.map(({ name, to }, index) => (
        <Step key={name}>
          <StepLabel>
            {activeStep <= index ? (
              <Typography>{name}</Typography>
            ) : (
              <Link component={RouterLink} to={to}>
                {name}
              </Link>
            )}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CMStepper;
